import Vue from 'vue'
import App from './App.vue'
import './../node_modules/bulma/css/bulma.css';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from 'axios'
import VueSignature from "vue-signature-pad";
import VueI18n from 'vue-i18n'
import messages from './lang'
import './../node_modules/flag-icon-css/css/flag-icon.min.css'
import './../node_modules/vue-step-progress/dist/main.css'
import router from './router'

Vue.use(VueI18n)
Vue.use(Buefy)
Vue.use(VueSignature);
Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.prototype.$appClient = 'FourParties'
Vue.prototype.$appUrl = 'https://www.fourparties.nl/vacatures'
Vue.prototype.$showStippPensionStep = true

const i18n = new VueI18n({
  locale: 'nl', // set locale
  messages, // set locale messages
})

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
